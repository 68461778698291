import React from "react";
import { navigate } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import useEffectOnce from "react-use/lib/useEffectOnce";
import LoginErrorPage from "~/features/auth/LoginErrorPage";
import LoadingSpinner from "~/components/LoadingSpinner";
import { ExternalLoginResponse } from "~/backendApi/model";
import { isStudentClassNotPermittedError } from "~/backendApi/loginClever";
import { useApiMutation } from "~/hooks/apiContext";
import { useAuthState } from "~/hooks/authContext";
import "~/styles/global.css";
import "~/features/marketing/general-page.css";

type ApiCallVariables = {
  readonly code: string;
  readonly redirectUri: string;
};

function OauthCleverPage() {
  const { origin, pathname, search } = useLocation();
  const code = new URLSearchParams(search).get("code");
  const redirectUri = origin + pathname;
  const { mutateAsync: loginClever, error } = useApiMutation<
    ApiCallVariables,
    ExternalLoginResponse
  >((c, params) => c.loginClever(params.code, params.redirectUri));
  const authState = useAuthState();
  useEffectOnce(() => {
    if (typeof code === "string") {
      (async () => {
        const response = await loginClever({ code, redirectUri });
        authState.signIn({
          username: response.username,
          emailAddress: undefined,
          loginType: response.loginType,
          type: response.userType,
          authToken: response.authToken,
        });
        await navigate("/dashboard/");
      })();
    }
  });

  if (!code) {
    return (
      <LoginErrorPage superTitle="Clever Login Error" title="No code provided">
        <p>There was some error trying to log in using clever</p>
      </LoginErrorPage>
    );
  }

  if (error && isStudentClassNotPermittedError(error)) {
    return (
      <LoginErrorPage
        superTitle="Clever Login Error"
        title="Student not permitted"
      >
        <p>You don't belong to any classes with access to the game.</p>
        <p>
          To get access to the game your teacher needs to log in to this website
          with their Clever details and permit your class.
        </p>
        <p>What would you like to do next?</p>
        <ul>
          <li>
            <a className="coloured-link" href="/dashboard/login/">
              Return to login
            </a>
          </li>
          <li>
            <a className="coloured-link" href="/">
              Return to home page
            </a>
          </li>
          <li>
            <a className="coloured-link" href="https://clever.com/">
              Go to clever.com
            </a>
          </li>
        </ul>
      </LoginErrorPage>
    );
  }

  if (error) {
    return (
      <LoginErrorPage
        superTitle="Clever Login Error"
        title="Error trying to login"
      >
        <p>{String(error)}</p>
      </LoginErrorPage>
    );
  }

  return (
    <div className="container general-page">
      <h2>Logging in using Clever</h2>
      <LoadingSpinner />
    </div>
  );
}

export default OauthCleverPage;
